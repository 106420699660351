import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import HomeMotivation from "./homemotivation.component";
//import UserService from "../services/user.service";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
   /* UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );*/
  }

  render() {
    return (
      <div className="container">
        <Carousel variant="dark">
          <Carousel.Item >
            <img 
              className="d-block"
              src="images/SlideShow1-S.jpg"
              alt=""
            />
            </Carousel.Item>
          <Carousel.Item >
            <img
              className="d-block"
              src="images/SlideShow2-S.jpeg"
              alt=""
            />
          </Carousel.Item>
          <Carousel.Item >
            <img
              className="d-block"
              src="images/SlideShow3-S.jpg"
              alt=""
            />
          </Carousel.Item>
          <Carousel.Item >
            <img
              className="d-block"
              src="images/SlideShow4-S.jpg"
              alt=""
            />
          </Carousel.Item>
         
        </Carousel>   
        <br/>
        <br/>
        <br/>
        <HomeMotivation></HomeMotivation>
        <br/>
      </div>
    );
  }
}
