import React, { Component } from "react";
import { Container } from "react-bootstrap";

//import UserService from "../services/user.service";

export default class HomeMotivation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

 

  render() {
    return (
     <Container>
          <div className="row">
            <div className="col-sm-4"> 
            <h3>WHAT SETS US APART</h3>
              At Rock Property Group, we have a defining culture of doing much more than just selling real estate. 
              We share a culture of serving each other, our communities and our planet. 
              Our mission, vision, values and culture differentiate us as industry innovators and leaders. 
              We use up to date real estate tools, 
              and focused marketing and search engine strategies to help sellers 
              achieve the best possible success in pricing, showing and marketing their properties.

            </div>
            <div className="col-sm-4"> 
            <h3>MISSION</h3>
              Delivering the dream of home ownership everywhere across the Western Cape.
              Owning real estate provides security, safety and opportunity for individuals. 
              The technology we build helps real estate agents become more efficient at their job and find more customers to deliver on that mission. 
              Our training prepares agents to be even better by utilizing exceptional techniques, training and systems, and the Rock Property Group Team 
              constantly help families find and sell homes.

          </div>
          <div className="col-sm-4"> 
          <h3>VISION</h3>
          Be Great and Do Good.
          We want to win at what we do, and we do all we can to help our customers, agents and employees build wealth. 
          We love to work with people who are passionate about working hard and also giving back to their communities, each in their own way.
          </div>
         
          
          </div>
          <br/>
        <br/>
        <br/>
          <div className="row">
            <div className="col-sm-4"> 
            <h3>VALUES</h3>
              Our values are what everyone who works at Rock Property Group strives for each day, and they are applicable to any companies we work with. 
              These values are also the metric we use to decide to invest or get in business with others. 
              We believe our team’s success is determined by our ability to maintain our defined core values:
            </div>
            <div className="col-sm-4"> 
            <h3>CORE VALUES</h3>
            <ul>
              <li> Knowledgeable. We strive to understand our markets and our clients’ needs.</li>
              <li>	Connected Team Work. Relationships are everything to us; we connect people to their homes and to their communities.</li>
              <li>	Passionate. We believe that working with “all heart” can change the world.</li>
              <li>Playful. We love what we do and it shows.</li>
              <li>Upstanding. Our clients’ needs and best interests are at the heart of everything we do.</li>
              <li>Effective Results & Accountability. We set a high bar and move mountains to exceed expectations.</li>
              <li>Integrity and Humility: We conduct ourselves in the highest ethical standards, demonstrating honesty and fairness in every decision and action.</li>
            </ul>
           
            </div>
            <div className="col-sm-4"> 
              <h3>WHAT WE DO</h3>
              <ul>
                <li>Our local Agents know that real estate is more than a house structure: it’s a community.</li>
                <li>Specialized departments collaborate to support our agents and you.</li>
                <li>We guide our clients through what can be a stressful and emotional process.</li>
                <li>Our experienced Agents strongly negotiate for you.</li>
                <li>To alleviate stress, we anticipate steps to ensure you’re informed and ready to take action.</li>
                <li>Our local Agents know that real estate is more than a house structure: it’s a community.</li>
              </ul>
            </div>
            </div>
    </Container>
    );
  }
}
