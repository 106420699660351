import React, { Component } from "react";


export default class Footer extends Component {
   // constructor(props) {
   //   super(props);
  
      
    //}


componentDidMount()
{

}

render () {
 return(
    <div className="text-center text-lg-start bg-dark text-muted footer-topbuffer footer">
{/*
      <section class="">
        <div class="container text-center text-md-start mt-1">
      
          <div class="row mt-1">
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-1">
              <h6 class="text-uppercase fw-bold mb-2">
                <i class="fas fa-gem me-3"></i>Rock Property Group
              </h6>
              <p>
                Can add some nice text here about a few things
              </p>
            </div>
          
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-1">
              <h6 class="text-uppercase fw-bold mb-2">
                Products
              </h6>
              <p>
                <a href="#!" class="text-reset">Buying</a><br/>
              
              
                <a href="#!" class="text-reset">Selling</a><br/>
              
             
                <a href="#!" class="text-reset">Renting</a>
              </p>
             
            </div>
          
            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-1">
              <h6 class="text-uppercase fw-bold mb-2">
                Useful links
              </h6>
             
              <p>
                <a href="#!" class="text-reset">Settings</a><br/>
             
                <a href="#!" class="text-reset">Orders</a><br/>
            
                <a href="#!" class="text-reset">Help</a>
              </p>
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-1">
              <h6 class="text-uppercase fw-bold mb-2">
                Contact
              </h6>
              <p>
                  <i class="fas fa-home me-3"></i> Cape Town, 8000<br/>
              
                <i class="fas fa-envelope me-3"></i>
                info@example.com<br/>
              
              <i class="fas fa-phone me-3"></i> + 01 234 567 88
              </p>
             
            </div>
          </div>
        </div>
      </section>
    */}
    
       

       
            <div className="text-center mt-2">
                © 2022 Copyright:
                <a class="text-reset fw-bold" href="/"> Rock Property Group</a>
            </div>
            <br/>
    </div>

 );   
}

}